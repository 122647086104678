import * as React from 'react';
import * as ReactDOM from 'react-dom';
import '@fontsource/mulish'
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Link, Typography } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import theme from './theme';

const BOTTOM_LINKS = [
    {
        title: 'Twitch',
        url: new URL('https://twitch.tv/malloth_rha'),
    },
    {
        title: 'RSS',
    }
];

const Antrum: React.FC = () => (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                width: '100%',
                px: '20px',
                background: `linear-gradient(75deg, #300, ${theme.palette.background.default}, ${theme.palette.background.default}, #003)`
            }}
        >
            <Typography variant='h2' pb='20px'>Antrum wird bald bei dir sein.</Typography>
            <Typography variant='body1' sx={{ maxWidth: '50em', color: '#ddd'}}>
                Antrum ist ein Horror-Mytery-Komedie Podcast in dem es um eine Gruppe von Überlebenden geht die aus dem "nichts" in ein augencheinlich unendliches Höhlensystem gelangt sind. Als zentrales Basislager dient die Höhlen-Höhle 0 (Null). Hier haben sich die Überlebenden eine Siedlung aufgebaut. Innerhalb von 0 steht eine alte Radiostation von der aus unser unglaublich gut aussehender, charmanter, wohlriechender Moderator "Du meinst doch aber hetzt nicht wirklich dich?!"
                ICH HABE GESAGT DU SOLLST MICH NICHT MEHR STÖREN FRANIZISBERTHA! Ich zeig dir jetzt was eine Schreib-maschine noch so alles afbia brvgouLPV RPgerfpdgwferoluv lj&lt;ds fpiEWALOHDIGFBVourvEUPWILJBVgf &mdash;
            </Typography>
        </Box>
        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'center',
                py: '6px',
            }}
        >
            {BOTTOM_LINKS.map(({ title, url }) => (
                <Box sx={{ px: '10px' }} key={`${url?.href}-${title}`}>
                    {
                        url
                            ? <Link href={url.href} underline='none'>{title}</Link>
                            : <Typography color='#555'>{title}</Typography>
                    }
                </Box>
            ))}
        </Box>
    </ThemeProvider>
)

window.onload = () => {
    const antrumDiv = document.getElementById('antrum');
    ReactDOM.render(<Antrum />, antrumDiv)
}

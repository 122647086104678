import { createTheme } from '@mui/material/styles';

export default createTheme({
  palette: {
      mode: 'dark',
      primary: {
          main: '#7c4bb8',
      },
      background: {
          default: '#17051c',
          paper: '#08020a',
      },
  },
  typography: {
      fontFamily: 'Mulish, Roboto, sans-serif',
  }
});